import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '../../molecules/Section'
import Blog from './Blog'
import Twitter from './Twitter'
import Events from './Events'
import styles from './index.module.css'

const query = graphql`
  query SectionLatestQuery {
    sectionLatest: allFile(
      filter: {
        name: { eq: "latest" }
        internal: { mediaType: { eq: "application/json" } }
      }
    ) {
      edges {
        node {
          childSectionsJson {
            title
            description
          }
        }
      }
    }

    events: allEventsJson(sort: { fields: date, order: DESC }, limit: 4) {
      edges {
        node {
          city
          eventName
          date
          date_end
          link
        }
      }
    }
  }
`

export default function SectionLatest() {
  const data = useStaticQuery(query)
  const {
    title,
    description
  } = data.sectionLatest.edges[0].node.childSectionsJson
  const events = data.events.edges
  const nextEvents = []
  const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString()
  for (let i = 0; i < events.length; i++) {
    if (events[i].node.date >= lastWeek) {
      nextEvents.push(events[i])
    }
  }

  return (
    <Section title={title} description={description}>
      <div className={styles.subSection}>
        <h3 className={styles.subSectionTitle}>Blog</h3>
        <Blog />
      </div>

      <div className={`${styles.subSection} ${styles.subSectionGrid}`}>
        <div>
          <h3 className={styles.subSectionTitle}>Twitter</h3>
          <Twitter />
        </div>

        <div>
          <h3 className={styles.subSectionTitle}>Next Events</h3>
          {nextEvents.length > 0 ? (
            <Events events={nextEvents} />
          ) : (
            <p>
              <i>Stay tuned for future events</i>
            </p>
          )}
        </div>
      </div>
    </Section>
  )
}
