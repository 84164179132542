import React from 'react'
import transformEventDate from './transformEventDate'
import styles from './Event.module.css'

/* eslint-disable camelcase */

export interface EventItem {
  city: string
  eventName: string
  date: string
  date_end?: string
  link: string
}

const Event = ({ event, box }: { event: EventItem; box?: boolean }) => (
  <a
    className={`${styles.event} ${box && styles.box}`}
    href={event.link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <h4 className={styles.city}>{event.city}</h4>
    <p className={styles.name}>{event.eventName}</p>
    <h5 className={styles.date}>
      {transformEventDate(
        event?.date?.replace(/-/g, '/'),
        event?.date_end?.replace(/-/g, '/')
      )}
    </h5>
  </a>
)

export default Event
