import React from 'react'
import { Link } from 'gatsby'
import Button from '../atoms/Button'
import { ReactComponent as Arrow } from '../../images/arrow.svg'
import Badge from '../atoms/Badge'
import styles from './Feature.module.css'

export interface FeatureProps {
  feature: string
  title: string
  text: string
  url: string
  badge?: string
  small?: boolean
}

export default function Feature({
  feature,
  title,
  text,
  url,
  badge,
  small
}: FeatureProps) {
  return (
    <Link to={url} className={`${styles.feature} ${small && styles.small}`}>
      <span className={styles.featureBadge}>
        {badge && <Badge className={styles.badge} label={badge} />} {feature}
      </span>
      <h2 className={styles.title}>{title}</h2>
      {!small && <p>{text}</p>}
      <Button
        className={styles.learnMore}
        style="text"
        size={small ? 'small' : null}
      >
        Learn more <Arrow />
      </Button>
    </Link>
  )
}
