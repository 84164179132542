import React, { useState, useEffect } from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { useInView } from 'react-intersection-observer'
import axios from 'axios'
import Button from '../../atoms/Button'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import stylesIndex from './index.module.css'
import styles from './Twitter.module.css'
import { useConsent, CookieConsentStatus } from '../../../hooks/useConsent'
import CookiePlaceholder from '../../molecules/CookiePlaceholder'

export default function Twitter() {
  const { social } = useSiteMetadata()
  const { cookieConsentStatus } = useConsent()
  const cookie = cookieConsentStatus === CookieConsentStatus.APPROVED
  const [ref, inView] = useInView({ triggerOnce: true })
  const [followers, setFollowers] = useState(0)

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios('https://oceanprotocol-community.now.sh')

        setFollowers(response.data.twitter.followers)
      } catch (error) {
        console.error(error.message)
      }
    }
    getData()
  }, [])

  const { url } = social.filter(
    (item: { name: string; url: string }) => item.name === 'Twitter'
  )[0]

  return (
    <>
      <div className={styles.timeline} ref={ref}>
        {inView && cookie ? (
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="oceanprotocol"
            noScrollbar
            transparent
            noFooter
            noHeader
            noBorders
            linkColor="#ff4092"
            options={{ width: 500, height: 420 }}
          />
        ) : inView && !cookie ? (
          <CookiePlaceholder />
        ) : null}
      </div>

      <div className={stylesIndex.subSectionActions}>
        <Button href={url} style="text">
          Join {followers} Twitter followers
        </Button>
      </div>
    </>
  )
}
