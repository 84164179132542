import React from 'react'
import shortid from 'shortid'
import Event, { EventItem } from '../SectionEvents/Event'
import styles from './Events.module.css'

export default function Events({ events }: { events: { node: EventItem }[] }) {
  return (
    <div className={styles.events}>
      {events.reverse().map(({ node }: { node: EventItem }) => (
        <Event key={shortid.generate()} event={node} />
      ))}
    </div>
  )
}
