import React, { CSSProperties, ReactNode } from 'react'
import styles from './Section.module.css'
import { markdownify } from '../../utils'

export interface SectionProps {
  title?: string
  subTitle?: string
  description?: string
  headerCenter?: boolean
  children: ReactNode
  id?: string
  style?: CSSProperties
  className?: string
}

export default function Section({
  title,
  subTitle,
  description,
  headerCenter,
  children,
  id,
  style,
  className
}: SectionProps) {
  return (
    <section
      className={`${styles.section} ${className && className}`}
      id={id}
      style={style}
    >
      {title && (
        <header className={`${styles.header} ${headerCenter && styles.center}`}>
          <h2 className={styles.title}>{title}</h2>
          {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
          {description && (
            <div className={styles.description}>{markdownify(description)}</div>
          )}
        </header>
      )}
      {children}
    </section>
  )
}
