import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import { graphql } from 'gatsby'
import { Post } from '../../../../api/blog'
import Button from '../../atoms/Button'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import stylesIndex from './index.module.css'
import styles from './Blog.module.css'

/* const query = graphql`
  query BlogPostsQuery {
    allMediumPosts {
      edges {
        node {
          id
          title
          url
          image
          subtitle
        }
      }
    }
  }
` */

export default function Blog() {
  const { social } = useSiteMetadata()
  /* const data = useStaticQuery(query)

  const initialPosts = data.allMediumPosts.edges.map(
    ({ node }: { node: Post }) => node
  ) */

  const [posts, setPosts] = useState<Post[]>([])
  const [followers, setFollowers] = useState(3236)

  // hydrate the blog posts: fetch them again on first mount
  // and if response is different update the posts state
  useEffect(() => {
    async function getData() {
      try {
        const response = await axios('/api/blog')

        if (response.data.posts && posts !== response.data.posts) {
          setPosts(response.data.posts)
        }

        setFollowers(response.data.followers)
      } catch (error) {
        console.error(error.message)
      }
    }
    getData()
  }, [])

  const { url } = social.filter(
    (item: { name: string; url: string }) => item.name === 'Blog'
  )[0]

  return (
    <>
      <div className={stylesIndex.subSectionGrid}>
        {posts && (
          <>
            <div>
              {posts.slice(0, 1).map((post: Post) => (
                <a key={post.id} href={post.url} className={styles.postLatest}>
                  <figure
                    className={styles.image}
                    style={{ backgroundImage: `url(${post.image})` }}
                  />
                  <div className={styles.content}>
                    <h4 className={styles.title}>{post.title}</h4>
                    {post.subtitle && (
                      <p className={styles.subtitle}>{post.subtitle}</p>
                    )}
                  </div>
                </a>
              ))}
            </div>
            <div>
              {posts.slice(1, 4).map((post: Post) => (
                <a key={post.id} href={post.url} className={styles.postSmall}>
                  <h4 className={styles.title}>{post.title}</h4>
                  {post.subtitle && (
                    <p className={styles.subtitle}>{post.subtitle}</p>
                  )}
                </a>
              ))}
            </div>
          </>
        )}
      </div>

      <div className={stylesIndex.subSectionActions}>
        <Button href={url} style="text">
          Join {followers} blog subscribers
        </Button>
      </div>
    </>
  )
}
