import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import PageHome from '../components/pages/home'
import tokenSwapNotificationContent from '../components/atoms/TokenSwapNotification'

export default function PageGatsbyHome(props: PageProps) {
  const { siteTagline, siteDescription } = useSiteMetadata()
  const content = (props.data as any).content.edges[0].node.childPagesJson
  const { actions } = content

  return (
    <Layout
      title={siteTagline}
      description={siteDescription}
      uri={props.uri}
      location={props.location}
      heroNotification={tokenSwapNotificationContent()}
      heroActions={actions}
      uniformHeroActionButtons
    >
      <PageHome />
    </Layout>
  )
}

export const contentQuery = graphql`
  query HomePageQuery {
    content: allFile(filter: { relativePath: { eq: "pages/home.json" } }) {
      edges {
        node {
          childPagesJson {
            actions {
              title
              url
            }
          }
        }
      }
    }
  }
`
