import React from 'react'
import classNames from 'classnames/bind'
import slugify from '@sindresorhus/slugify'
import { graphql, useStaticQuery } from 'gatsby'
import shortid from 'shortid'
import Button from '../atoms/Button'
import Section from '../molecules/Section'
import styles from './SectionMediaMentions.module.css'

const cx = classNames.bind(styles)

const query = graphql`
  query SectionMediaMentionsQuery {
    mediamentionsJson {
      title
      link {
        title
        url
      }
      publications {
        name
        logo {
          publicURL
        }
        url
      }
    }
  }
`

export interface Publication {
  name: string
  logo: {
    publicURL: string
  }
  url: string
}

export default function SectionMediaMentions({
  hasLink
}: {
  hasLink?: boolean
}) {
  const data = useStaticQuery(query)
  const { title, link, publications } = data.mediamentionsJson

  return (
    <>
      <div className={styles.info}>
        <Button
          href="https://oceanprotocol.com/press/2021-06-15-ocean-awarded-world-economic-forum-technology-pioneer"
          style="text"
        >
          Ocean Protocol is a World Economic Forum Technology Pioneer.
        </Button>
      </div>
      <Section title={title} headerCenter className={styles.section}>
        <ul className={styles.publications}>
          {publications.map((item: Publication) => {
            const classes = cx({
              publication: true,
              [slugify(item.name)]: true
            })

            return (
              <li key={shortid.generate()}>
                <a
                  className={classes}
                  href={item.url}
                  title={item.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.logo && (
                    <img src={item.logo.publicURL} alt={item.name} />
                  )}
                </a>
              </li>
            )
          })}
        </ul>
        {hasLink && (
          <aside className={styles.link}>
            <Button style="text" size="small" to={link.url}>
              {link.title}
            </Button>
          </aside>
        )}
      </Section>
    </>
  )
}
