import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Feature from './Feature'
import styles from './FeaturesList.module.css'

const query = graphql`
  query FeaturesQuery {
    features: allFile(
      filter: {
        relativeDirectory: { eq: "pages/technology" }
        internal: { mediaType: { eq: "application/json" } }
        name: { nin: ["overview", "roadmap"] }
      }
      sort: { fields: childTechnologyJson___order }
    ) {
      edges {
        node {
          name
          childTechnologyJson {
            order
            feature
            badge
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 291) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`

export default function FeaturesList({ small }: { small?: boolean }) {
  const { features } = useStaticQuery(query)

  return (
    <ul className={`${styles.features} ${small && styles.small}`}>
      {features.edges.map(({ node }: { node: any }) => {
        const {
          feature,
          badge,
          title,
          description,
          image,
          fields
        } = node.childTechnologyJson

        return (
          <li key={node.name} className={styles.feature}>
            {image && !small && (
              <Img
                fluid={image.childImageSharp.fluid}
                alt={title}
                Tag="figure"
                className={styles.image}
              />
            )}
            <Feature
              title={title}
              text={description}
              url={fields.slug}
              feature={feature}
              badge={badge}
              small={small}
            />
          </li>
        )
      })}
    </ul>
  )
}
