import React from 'react'
import Button from '../atoms/Button'
import { useConsent } from '../../hooks/useConsent'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './CookiePlaceholder.module.css'

const query = graphql`
  query CookiePlaceholderQuery {
    gdpr: allFile(filter: { relativePath: { eq: "gdpr.json" } }) {
      edges {
        node {
          childContentJson {
            cookies {
              placeholder
            }
          }
        }
      }
    }
  }
`

export default function CookiePlaceholder() {
  const { resetConsentStatus } = useConsent()
  const data = useStaticQuery(query)
  const { cookies } = data.gdpr.edges[0].node.childContentJson

  return (
    <div className={styles.placeholder}>
      <p>{cookies.placeholder}</p>
      <Button style="text" onClick={resetConsentStatus}>
        Cookie settings
      </Button>
    </div>
  )
}
