import React from 'react'
import FeaturesList from '../../molecules/FeaturesList'
import SectionLatest from '../../organisms/SectionLatest'
import SectionMediaMentions from '../../organisms/SectionMediaMentions'
import styles from './index.module.css'

export default function PageHome() {
  return (
    <>
      <SectionMediaMentions />

      <div className={styles.content}>
        <FeaturesList />
      </div>

      <SectionLatest />
    </>
  )
}
