import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query TokenSwapQuery {
    content: allFile(filter: { relativePath: { eq: "tokenSwap.json" } }) {
      edges {
        node {
          childContentJson {
            title {
              default
              timer
              progress
              done
            }
            url
          }
        }
      }
    }
  }
`

export default function tokenSwapNotificationContent() {
  const data = useStaticQuery(query)
  const content = data.content.edges[0].node.childContentJson
  const title = content.title.done

  return { title, url: content.url }
}
